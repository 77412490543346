import React, { useEffect, useState } from 'react';
import { _ } from 'core-js';
import Throbber from '../throbber';
import PollsManager from '../../managers/Polls';
import AccountManager from '../../managers/Account';

const MAX_POLL_OPTION_TEXTAREA_LENGTH = 2000;

const PollsEntry = ({ poll, onPollSubmission, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pollConfiguration, setPollConfiguration] = useState(null);
  const [pollAnswers, setPollAnswers] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState({});
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [remainingChars, setRemainingChars] = useState(MAX_POLL_OPTION_TEXTAREA_LENGTH);
  const [exceededChars, setExceededChars] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isMultipleChoice, setIsMultipleChoice] = useState(false);

  const makeChoice = (choice, e) => {
    if (e) {
      e.preventDefault();
    }
    setShowErrorMsg(false);
    if (selectedChoice && selectedChoice.id === choice.id) {
      setSelectedChoice({});
    } else {
      setSelectedChoice(choice);
    }
  };

  const submitPoll = async () => {
    setIsLoading(true);
    const payload = {
      pollId: poll.pollId,
      selectedPollOptionId: selectedChoice.id,
      answerText: pollAnswers.length !== 1 ? selectedChoice.description : textAreaValue,
    };
    try {
      if (!isMultipleChoice && textAreaValue.length === 0) {
        throw new Error("Can't submit an empty answer.");
      }

      if (!isMultipleChoice && textAreaValue.length >= MAX_POLL_OPTION_TEXTAREA_LENGTH) {
        throw new Error('Character limit reached.');
      }

      const pollResponse = await PollsManager.submitPoll(AccountManager.getToken(), payload);
      if (pollResponse && pollResponse.success) {
        setIsLoading(false);
        setIsSuccess(true);
        localStorage.setItem('ETHO_POLL_' + poll.utilityId, true);
        onPollSubmission();
      } else {
        throw new Error(pollResponse.message || 'Error submitting poll. Please try again.');
      }
    } catch (error) {
      setIsLoading(false);
      setShowErrorMsg(true);
      setErrorMsg(error.message);
    }
  };

  const closeModal = (e) => {
    e.preventDefault();
    onClose();
  };

  const checkForExistingPollSubmission = (utilityId) => {
    const pollSubmission = localStorage.getItem('ETHO_POLL_' + utilityId);
    if (pollSubmission) {
      setIsSuccess(true);
    }
  };

  useEffect(() => {
    if (poll) {
      checkForExistingPollSubmission(poll.utilityId);
      setPollConfiguration(poll.pollConfiguration);
      setPollAnswers(poll.pollOptions);
      if (poll.pollOptions[0].pollOptionType === 1) {
        setSelectedChoice(poll.pollOptions[0]);
      }
      if (poll.pollOptions.length > 1) {
        setIsMultipleChoice(true);
      }
    }
  }, [poll]);

  const handleTextAreaChange = (e) => {
    const newText = e.target.value;
    setTextAreaValue(newText);
    if (errorMsg != null && newText.length <= MAX_POLL_OPTION_TEXTAREA_LENGTH && newText.length !== 0) {
      setShowErrorMsg(false);
    }
    if (remainingChars <= 0) {
      setExceededChars(newText.length - MAX_POLL_OPTION_TEXTAREA_LENGTH);
    }
    setRemainingChars(MAX_POLL_OPTION_TEXTAREA_LENGTH - newText.length);
  };

  const isErrorState = remainingChars <= 0;

  return (
    <div className="contest-modal">
      {isLoading
      && <Throbber throbberText="Submitting response..." />}
      <div className="polls-modal__polls-header">
        <div className="contest-modal__header">
          {poll.title}
        </div>
        {pollConfiguration && pollConfiguration.pointsAwarded > 0 && (
          <div className="contest-modal__prize-description">
            {isSuccess ? (
              <span className="contest-modal__points-completed">Completed</span>
            ) : (
              <span>Earn</span>
            )}
            {': '}
            <span className="contest-modal__points-amount">
              {pollConfiguration.pointsAwarded}
              {' '}
              points
            </span>
          </div>
        )}
      </div>
      {!isSuccess && (
      <form>
        <div className="polls-modal__polls-body">
          <div className="polls-modal__polls-body--inner">
            <div className="polls-modal__polls-body--description">
              {poll.description}
            </div>
            <div className="polls-modal__question">
              <div className={poll.pollOptions[0].pollOptionType === 2 ? 'polls-modal__image--choices' : 'polls-modal__text--choices'}>
                { pollAnswers.length > 1 && (
                  pollAnswers.map((choice) => (
                    <div
                        key={choice.id}
                        className="polls-modal__option--choice"
                      onClick={() => makeChoice(choice)}>
                      <div>
                        {choice.pollOptionLinkUrl
                        && <div><img src={choice.pollOptionLinkUrl} alt="choice 1" /></div>}
                        <div className="polls-modal__option--choice-inner">
                          <input
                            type="radio"
                            value={choice.id}
                            name={'choice' + choice.id}
                            id={'choice' + choice.id}
                            checked={choice.id === selectedChoice.id}
                            readOnly />

                          {choice.description}
                        </div>
                      </div>
                    </div>
                  ))
                )}
                { poll.pollOptions[0].pollOptionType === 1 && (
                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                  <label htmlFor="freeform-benefit-poll-option" style={{ color: '#4A5568', display: 'block', marginBottom: '4px' }}>Leave your response to this poll</label>
                  <textarea
                    id="freeform-benefit-poll-option"
                    style={{
                      marginTop: '8px',
                      marginRight: '16px',
                      borderRadius: '10px',
                      height: '64px',
                      border: '1px solid',
                      outline: 'none',
                      borderColor: isErrorState ? '#F56565' : '#CBD5E0',
                      backgroundColor: isErrorState ? '#FED7D7' : 'white',
                      width: '95%',
                      padding: '8px',
                      boxSizing: 'border-box',
                    }}
                    placeholder="Enter response..."
                    value={textAreaValue}
                    onChange={handleTextAreaChange} />
                  <p
                    style={{
                      fontSize: '12px',
                      marginTop: '8px',
                      color: isErrorState ? '#F56565' : '#A0AEC0',
                    }}>
                    {isErrorState
                      ? `Response character limit exceeded: ${exceededChars}`
                      : `Response character limit: ${remainingChars}`}
                  </p>
                </div>
                )}
              </div>
            </div>
            
          </div>
        </div>
      </form>
      )}
      {isSuccess && (
      <div className="contest-modal__success">
        <img src="https://assets.heyethos.com/ethos/v2/ui/icon-success.png" alt="Poll success" />
        <div className="poll-modal__success">{poll.completionMessage}</div>
      </div>
      )}
      <div className="polls-modal__polls-footer">
        {showErrorMsg && (
        <div className="polls-modal__message -error">
          {errorMsg || 'Error submitting poll. Please try again.'}
        </div>
        )}

        <div className="polls-modal__cta">
          {isSuccess ? (
            <button
                type="button"
                className="button-collection"
                onClick={(e) => closeModal(e)}>
              Done
            </button>
          ) : (
            <button
                type="button"
                className="button-collection"
                onClick={() => submitPoll()}
                disabled={selectedChoice && !selectedChoice.id}>
              Submit
            </button>
          )}
        </div>
      </div>

    </div>
  );
};
export default PollsEntry;
