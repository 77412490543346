/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-mini';
import Throbber from '../throbber';
import UtilityManager from '../../managers/Utility';
import AccountManager from '../../managers/Account';
import ContestStaticTerms from './contestStaticTermsV042402';

const ContestRules = () => {
  const [contestDetails, setContestDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { contestId } = useParams();
  //   const toggleAnswer = (id, e) => {
  //     e.preventDefault();
  //     const cloneQuestions = [...questions];
  //     cloneQuestions.forEach((faq) => {
  //       if (faq.id === id) {
  //         faq.isOpen = !faq.isOpen;
  //       }
  //     });
  //     setQuestions(cloneQuestions);
  //   };

  const getContestDetails = async (id) => {
    const contest = await UtilityManager.getContestRules(id, AccountManager.getToken());
    if (contest && contest.success) {
      setContestDetails(contest.contest);
    } else {
      setHasError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log('=== Contest Rules ===');
    console.log(contestId);
    if (contestId) {
      getContestDetails(contestId);
    }
  }, [contestId]);

  return (
    <div className="contest-rules">
      {isLoading
        && <Throbber throbberText="Loading contest rules Please stand by..." />}
      {!isLoading && !hasError && contestDetails && (
        <div className="contest-rules__static">
          <div className="contest-rules__container">
            {contestDetails.creatorName
            && (
            <div className="contest-rules__row">
              <div className="contest-rules__label">BRAND:</div>
              <div className="contest-rules__value">{contestDetails.creatorName}</div>
            </div>
            )}
            <div className="contest-rules__row">
              <div className="contest-rules__label">INTERNATIONAL CONTEST NAME:</div>
              <div className="contest-rules__value">{contestDetails.title}</div>
            </div>
            <div className="contest-rules__row">
              <div className="contest-rules__label">PARTICIPATING REGIONS:</div>
              <div className="contest-rules__value">USA / UK / CANADA</div>
            </div>
            <div className="contest-rules__row">
              <div className="contest-rules__label">CONTEST START DATE:</div>
              <div className="contest-rules__value">{moment(contestDetails.startDate).format('MMMM DD, YYYY')}</div>
            </div>
            <div className="contest-rules__row">
              <div className="contest-rules__label">CONTEST END DATE:</div>
              <div className="contest-rules__value">{moment(contestDetails.endDate).format('MMMM DD, YYYY')}</div>
            </div>
            <div className="contest-rules__row">
              <div className="contest-rules__label">PRIZE DESCRIPTION:</div>
              <div className="contest-rules__value">{contestDetails.description}</div>
            </div>
            <div className="contest-rules__row">
              <div className="contest-rules__label">PRIZE DESCRIPTION:</div>
              <div className="contest-rules__value">{contestDetails.prizeDescription}</div>
            </div>
            <div className="contest-rules__row">
              <div className="contest-rules__label">PRIZE QUANTITY:</div>
              <div className="contest-rules__value">{contestDetails.prizeNumber}</div>
            </div>
            <div className="contest-rules__row">
              <div className="contest-rules__label">PRIZE VALUE EST. (MAX USD 5,000):</div>
              <div className="contest-rules__value">
                $
                {contestDetails.prizeValue}
              </div>
            </div>
          </div>
          <ContestStaticTerms />
        </div>
      )}
      {!isLoading && hasError && (
        <div className="contest-rules__static">
          <h3>Oops! Something went wrong</h3>
          <p>
            We are unable to load the contest rules at this time. Please try again later.
          </p>
        </div>
      )}
    </div>
  );
};
export default ContestRules;
