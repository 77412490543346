import React, { useState, useEffect } from 'react';

const ContestStaticTerms = () => {
  useEffect(() => {
    console.log('=== Contest static terms ===');
  }, []);

  return (
    <div>
      <p>
        THESE RULES ARE THE INTERNATIONAL TERMS AND CONDITIONS APPLICABLE TO THE
        INTERNATIONAL CONTEST AND GIVEAWAY (THE “CONTEST”). YOU, AS AN ENTRANT TO OR
        PARTICIPANT IN THE CONTEST, ARE A PARTY TO THESE CONEST RULES. IN THESE CONTEST
        RULES, YOU MAY BE REFERRED TO AS “YOU”, “ENTRANT”, “SELECTED ENTRANT” IF
        APPROPRIATE OR “WINNER” IF APPROPRIATE. THE BRAND MAY OFFER YOU THE ABILITY TO
        ENTER THE CONTEST AT ITS SOLE DISCRETION AS A FORM OF MEMBERSHIP BENEFIT
        ASSOCIATED WITH YOUR MEMBERSHIP IN THE BRAND’S LOYALTY PROGRAM. THE BRAND IS
        THE PROVIDER AND ADMINISTRATOR OF THE CONTEST AND ACCORDINGLY IS A PARTY TO
        THESE CONTEST RULES. NO PURCHASE IS NECESSARY. VOID IN WHOLE OR IN PART WHERE
        PROHIBITED BY LAW. ENTRY IN THE CONTEST AND/OR BY CLICKING A BOX OR BUTTON
        CONSTITUTES YOUR ACKNOWLEDGMENT, ACCEPTANCE AND FULL COMPLIANCE WITH THESE
        CONTEST RULES (THE “CONTEST RULES”).
      </p>
      <p className="contest-rules__underline">ELIGILBILITY</p>
      <p>To be eligible to participate in the Contest you must:</p>
      <ul>
        <li>Be an individual (businesses, organizations or other legal structures are not eligible);</li>
        <li>Be a legal resident of the United States, the United Kingdom or Canada (however residents of the state of Rhode Island are not eligible if the Brand operates a retail location within Rhode Island);</li>
        <li>Be the age of majority in the region in which you are a legal resident; and</li>
        <li>
          Have valid and participating membership in the Brand loyalty program as at the Contest end date.
          <ul>
            <li>If the Contest is limited to a specific membership tier, you must have accomplished the requirements to qualify for the relevant tier as at the Contest start date.</li>
            <li>If the Contest is limited to a specific membership for which you paid a fee to receive, you must also have held the membership as at the Contest start date.</li>
          </ul>
        </li>
      </ul>
      <p>
        If you do not meet the above conditions, you are not eligible to participate in the Contest and if
        you enter the Contest by any other means you will not be eligible to be a selected entrant or a
        winner of the Contest and you will be disqualified without any further notification. If you do not
        have a membership in the Brand loyalty program, please visit the Brand website or other online
        properties.
      </p>

      <p>
        Employees of a) the Brand, its parent, affiliates, subsidiaries, related companies, successors and
        assigns, or b) related advertising and promotional agencies, or a household member of any of
        the above, are not eligible to enter or participate in the Contest.
      </p>

      <p>
        The Brand shall have the right at any time to require proof of identity and/or eligibility to
        participate in the Contest. Failure to provide such proof will result in your disqualification. All
        personal and other information requested by and supplied to the Brand for the purpose of the
        Contest must be truthful, complete, accurate and in no way misleading. The Brand reserves the
        right, in its sole discretion, to disqualify you, or any other entrant, should you or such entrant
        supply untruthful, incomplete, inaccurate or misleading personal details and/or information.
      </p>

      <p className="contest-rules__underline">CONTEST PERIOD</p>
      <p>
        The Contest entry period (the “Contest Period”) shall start on the Contest start date set out
        above and shall end on the Contest end date set out above after which time the Contest will be
        closed and no other entries shall be accepted.
      </p>
      <p className="contest-rules__underline">HOW TO ENTER</p>

      <p>
        There is no purchase necessary to enter the Contest. The means by which you may enter the
        Contest may include (i) visit the Brand’s website or other online properties and click the
        applicable box or button, or (ii) visit the Brand’s other online properties and click the applicable
        box or button or (iii) enter as otherwise indicated by the Brand. Entries must be received prior
        to the end of the applicable Contest Period to be eligible for entry. You may not receive any
        notification or confirmation of receipt of your entry into the Contest.
      </p>
      <p>
        Entry is limited to one entry per individual. In the case of multiple entries received only the first
        eligible entry will be considered for eligibility and the remainder will be void without further
        notice to you.
      </p>
      <p>
        Entries received shall be deemed to be submitted by the authorized account holder of the e-
        mail address of the membership associated with the entry, as applicable. For the purposes of
        these Contest Rules, “authorized account holder” of an e-mail address or a user account is
        defined as the natural person who is assigned to an e-mail address or user account by an
        Internet access provider, on-line service provider or other organization responsible for
        assigning such addresses and accounts. Each selected entrant may be required to provide the
        Brand with appropriate proof that the selected entrant is the authorized account holder of the
        e-mail address or user account associated with the selected entry or winning entry.
      </p>

      <p className="contest-rules__underline">PRIZES</p>
      
      <p>
        Each Contest prize shall have an approximate retail value as set out above and may be further
        described on the Brand’s website or other online properties prior to or at the beginning of each
        Contest Period. Winners shall not be entitled to receive the difference between the actual prize
        value and the approximate prize value.
      </p>
      <p>
        Selected entrants for the Contest will be notified with instructions, conditions and
        requirements relating to potential qualification as the winner of the Contest and, if you qualify
        as the winner of the Contest, how to claim the prize. Prizes must be accepted as offered and
        awarded by the Brand and cannot be transferred, assigned, substituted or redeemed for cash.
        Any unused portion of a prize will be forfeited and will have no cash value. The Brand reserves
        the right, in its sole discretion, to substitute a prize of equal or greater value if a prize (or any
        portion thereof) cannot be awarded to the winner for any reason.
      </p>
      <p>
        The Brand shall not assume any liability for lost, damaged or misdirected prizes. In the case of
        concert or event tickets as a prize, if an artist, performer or any relevant band members are
        unable to attend the applicable concert or event or a concert or event is cancelled for any
        reason whatsoever, the prize and any prize portions shall be deemed fulfilled and such prize or
        prize portions will not be substituted. In the case of gift cards as a prize, the terms by which the
        gift cards may be redeemed are governed by the applicable gift card provider and the Brand is
        not responsible for establishing or administering such terms. In the case of any other form or
        structure of prize, the Brand is not responsible for the performance, quality or longevity of the
        prize.
      </p>
      <p>
        All incidental or related costs and expenses not specifically referred to herein as part of the
        prize description are the sole responsibility of the winner and/or guest(s), where applicable. All
        prizes, and components of all prizes, are subject to availability and substitution. The winner
        shall not seek reimbursement for any incidental or related costs and expenses from any party
        including the Brand
      </p>
            
      <p className="contest-rules__underline">WINNER SELECTION</p>

      <p>
        Following the end of the applicable Contest Period, one entrant will be selected (or more, if
        applicable, based on the number of prizes available to be won) by a random draw from eligible
        entries received during the Contest Period, unless otherwise indicated. Each entrant shall be
        eligible to win only one prize, unless otherwise indicated. The odds of being drawn as a selected
        entrant are dependent upon the number of eligible entries received for the Contest. Any
        unselected entrants or any remaining entries will not be carried over for entry into any other
        contest.
      </p>
      <p>
        The selected entrant may be notified by email. As a prerequisite condition prior to being
        declared the winner, the selected entrant shall be required to respond to such notification to a)
        correctly answer, without assistance of any kind, whether mechanical or otherwise, a time-
        limited mathematical skill-testing question, b) provide personal information including legal
        name and residential address, c) declare willingness to claim and irrevocably accept the prize,
        and d) attest to compliance with these Contest Rules and specifically attest to the acceptance
        of the Release set out within these Contest Rules. Such response by the selected entrant must
        be a) in writing and b) received within the specified timeframe (the “Response”). If the selected
        entrant does provide the Response within the specified timeframe, or if the selected entrant
        does not provide a Response positively and appropriately and confirm terms a), b), c) and d)
        above, such selected entrant will be disqualified without any further notification and will not be
        declared the winner and will not receive a prize and another entrant may be selected until such
        time as a selected entrant provides a Response that satisfies all terms set out herein. The Brand
        is not responsible for the failure for any reason whatsoever of a) any selected entrant to receive
        the notification by email, b) any selected entrant to read or understand the notification, c) the
        Brand to receive any selected entrant’s Response, and any such failure may result in the
        disqualification of the selected entrant without any further notification.
      </p>
      <p>
        If, as a result of an error relating to the entry process, drawing or any other aspect of the
        administration of the Contest, there are more selected entrants than contemplated, there will
        be a random draw amongst such selected entrants to identify the correct number of selected
        entrants for the Contest. You acknowledge that you have no right to challenge the results of the
        Contest, including the identification of any selected entrant or winner of the Contest.
      </p>
      <p>
        Prizes shall be shipped, where applicable, or otherwise provided or fulfilled by the Brand to the
        winner at the registered address of the winner within the Participating Regions and using the
        shipping method as determined solely by the Brand.
      </p>

      <p className="contest-rules__underline">RELEASE</p>
      <p>
        <strong>
          You understand and agree that by accepting these Contest Rules you hereby: (i) confirm your
          eligibility for the Contest and your full compliance with these Contest Rules; (ii) agree to
          unconditionally accept the prize as offered by the Brand if you are selected as the selected
          entrant and are declared the winner of the Contest; (iii) agree to an unconditional release
          (“Release”) of the Brand and its respective parent companies, subsidiaries, affiliates and/or
          related companies and each of their employees, directors, officers, suppliers, agents,
          administrators, licensees, contractors, suppliers, partners, representatives, advertising, media
          buying and promotional agencies (collectively, the “Releasees”) from any and all liability for
          any loss, harm, damages, cost or expense arising out of a) participation in the Contest, b)
          participation in any Contest-related activities, c) the acceptance, use or misuse of any prize,
          including but not limited to costs, injuries, losses related to personal injuries, death, damage
          to, loss or destruction of property, rights of publicity or privacy, defamation, or portrayal in a
          false light, or from any and all claims of third parties arising therefrom, and d) failure of the
          Contest or any prize to meet your expectations in any way. You acknowledge that your
          participation in the Contest is at your sole risk and that your acceptance and use of any prize
          is at your sole risk. Further, by accepting these Contest Rules you grant to the Brand the
          unrestricted perpetual right to produce, reproduce, convert, publish, broadcast,
          communicate by telecommunication, exhibit, distribute, translate, adapt, and otherwise use
          or re-use in any manner whatsoever your name, likeness, comments, location, Contest won,
          prize claimed, date of Contest in any and all media now known or hereafter devised in
          connection with publicity related to the Contest.
        </strong>
      </p>
      <p className="contest-rules__underline">INDEMNIFICATION BY ENTRANT</p>
      <p>
        By entering the Contest or participating in any way with the Contest, you release and hold the
        Releasees harmless from any and all liability for any injuries, loss or damage of any kind to you
        or any other person, including personal injury, death, or property damage, resulting in whole or
        in part, directly or indirectly, from acceptance, possession, use or misuse of any prize,
        participation in the Contest, any breach of the Contest Rules, or in any prize-related event or
        activity. You agree to fully indemnify Releasees from any and all claims by third parties relating
        in any way directly or indirectly to the Contest, without limitation.
      </p>
      <p className="contest-rules__underline">LIMITATION OF LIABILITY</p>
      <p>
        <strong>
          The Brand has no liability to you or to any other party other than as related to the provision
          of the prize to the declared winner of the Contest. Neither the Brand nor the Releasees shall
          assume any responsibility or any liability to you or to any other party related in any manner,
          whether directly or indirectly, to the Contest.
        </strong>
        {' '}
        Neither the Brand nor the Releasees shall
        assume any responsibility or liability for lost, late, unintelligible / illegible, falsified, damaged,
        misdirected or incomplete entries, notifications, responses, replies, or for any computer, online,
        software, telephone, hardware or technical malfunctions that may occur, including but not
        limited to malfunctions that may affect the transmission or non-transmission of an entry or a
        Response. Neither the Brand nor the Releasees are responsible for any incorrect or inaccurate
        information, whether caused by users or by any of the equipment or programming associated
        with or utilized in the Contest or by any technical or human error which may occur in the
        administration of the Contest. Neither the Brand nor the Releasees shall assume any
        responsibility for any error, omission, interruption, deletion, defect, delay in operation or
        transmission, communications line failure, theft or destruction or unauthorized access to, or
        alteration of, entries. Neither the Brand nor the Releasees are responsible for any problems,
        failures or technical malfunction of any telephone network or lines, computer online systems,
        servers, providers, computer equipment, software, e-mail, or browsers, on account of technical
        problems or traffic congestion on the Internet, at any website, or on account of any
        combination of the foregoing or otherwise. Neither the Brand nor the Releasees are
        responsible for any injury or damage to you or to any entrant or to any computer related to or
        resulting from participating or downloading materials related to the Contest. You assume
        liability for injuries caused or claimed to be caused by participating in the Contest, or by the
        acceptance, possession, use of, or failure to receive any prize. Neither the Brand nor the
        Releasees shall assume responsibility or liability in the event that the Contest cannot be
        conducted as planned for any reason, including those reasons beyond the control of the Brand,
        such as infection by computer virus, bugs, tampering, unauthorized intervention, fraud,
        technical failures, or corruption of the administration, security, fairness, integrity or proper
        conduct of the Contest and/or the relevant websites or online properties.
      </p>
      <p>
        By participating in the Contest, you agree to be bound by these Contest Rules. You further
        agree to be bound by the decisions of the Brand as the administrator of the Contest, which shall
        be final and binding in all respects. The Brand reserves the right, in its sole discretion, to
        disqualify any entrant found to be: (a) violating the Contest Rules; (b) tampering or attempting
        to tamper with the entry process or the operation of the Contest, any website or online
        properties, or any related promotional website; (c) violating the terms of service, terms of use
        and/or general rules or guidelines of any products or services offered by the Brand; and/or (d)
        acting in an unsportsmanlike or disruptive manner, or with intent to annoy, abuse, threaten or
        harass any other person or entity. Any attempt to deliberately damage any relevant website or
        relevant online properties or undermine the legitimate operation of the Contest may be a
        violation of criminal and civil laws. Should such an attempt be made, the Brand reserves the
        right to seek remedies and damages to the fullest extent permitted by law, including but not
        limited to criminal prosecution. If applicable, winners (and guests of winners, if applicable) must
        at all times behave appropriately when using, experiencing or taking part in the prize in any way
        and must at all times observe the Contest Rules and any other rules, laws and regulations in
        force at any prize-related events or locations. The Brand reserves the right to remove from any
        prize-related locations, any winner and/or guest who breaks such rules and/or fails to behave
        appropriately and to disqualify such winner.
      </p>
      <p className="contest-rules__underline">PRIVACY / USE OF PERSONAL INFORMATION</p>
      <p>
        By entering or participating in the Contest in any way you: (i) grant to the Brand the right to use
        your personal information provided by upon entering the Contest or otherwise provided
        through your enrollment in any membership in the Brand loyalty program (collectively
        “Personal Information”) for the purpose of administering the Contest, including but not limited
        to contacting and announcing the selected entrants and winners and coordinating the
        fulfilment, provision and shipping of any prize; (ii) grant to the Brand the right to use your
        Personal Information for publicity and promotional purposes relating to the Contest in any and
        all media without any further compensation; and (iii) acknowledge that the Brand may disclose
        Personal Information to third-party agents, contractors and service providers in connection
        with any of the activities listed in (i) and/or (ii) above. The Brand represents to use your
        Personal Information only for identified purposes and warrants to protect your Personal
        Information in a manner that is consistent with the Brand’s privacy policy.
      </p>
      <p className="contest-rules__underline">INTELLECTUAL PROPERTY</p>
      <p>
        All intellectual property, including but not limited to trade-marks, trade names, logos, designs,
        promotional materials, web pages, source code, drawings, illustrations, slogans and
        representations are owned by the Brand and/or its contactors, partners and affiliates. All rights
        are reserved. Unauthorized copying or use of any copyrighted material or intellectual property
        without the express written consent of the Brand is strictly prohibited.
      </p>
      <p className="contest-rules__underline">TERMINATION</p>
      <p>
        The Brand reserves the right, in its sole discretion, to terminate the Contest, in whole or in part,
        and/or modify, amend or suspend the Contest, and/or the Contest Rules in any way, at any
        time, for any reason without prior notice.
      </p>
      <p className="contest-rules__underline">JURISDICTION</p>
      <p>
        These are the official Contest Rules. The Contest shall be conducted internationally and shall be
        conducted, construed and evaluated according to applicable law. The Contest Rules are subject
        to change without notice for any reason including in order to comply with any applicable laws
        or the policy of any other entity having jurisdiction over the Brand and/or the Contest. All issues
        and questions concerning the construction, validity, interpretation and enforceability of the
        Contest Rules or the rights and obligations between you and the Brand shall be governed by
        and construed in accordance with the laws of the state or province or jurisdiction of
        incorporation of the Brand.
      </p>
      <p className="contest-rules__underline">LANGUAGE DISCREPANCY</p>
      <p>
        In the event of any discrepancy or inconsistency between these Contest Rules and disclosures
        or other statements contained in any Contest-related materials, including but not limited to any
        point of sale, television, print or online advertising, these Contest Rules in English shall prevail,
        govern and control.
      </p>
    </div>
  );
};
export default ContestStaticTerms;
