/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import AccountManager from '../../managers/Account';
import { isMobile } from '../../managers/Helpers';

function CustomBenefits({
  membershipId, tierId, isFollowing, isFollowingStrava, onInstagramFollow, onStravaFollow, onReferFriend, onShareLink, features, readOnly, onPreviewCTA, isPreviewMode,
}) {
  const [announcements, setAnnouncements] = useState([]);
  const [membershipFeatures, setMembershipFeatures] = useState([]);
  const [instagramUrl, setInstagramUrl] = useState('');
  const [stravaUrl, setStravaUrl] = useState('');

  const getInstagramConfig = async (membershipId) => {
    const instaConfig = await AccountManager.getInstagramConfiguration(AccountManager.getToken(), membershipId);
    if (instaConfig && instaConfig.success) {
      instaConfig.configuration.instagramConfigurations.forEach((config) => {
        if (config.membershipTierIds.includes(tierId)) {
          setInstagramUrl(config.instagramUrl);
        }
      });
    }
  };

  const membershipConfig = async (membershipId) => {
    const config = await AccountManager.getInstagramConfiguration(AccountManager.getToken(), membershipId);
    if (config && config.success) {
      config.configuration.instagramConfigurations.forEach((item) => {
        if (item.membershipTierIds.includes(tierId)) {
          setInstagramUrl(item.instagramUrl);
        }
      });
      if (config.configuration.stravaConfigurations) {
        config.configuration.stravaConfigurations.forEach((item) => {
          if (item.membershipTierIds.includes(tierId)) {
            setStravaUrl(item.stravaUrl);
          }
        });
      }
    }
  };

  const followOnStrava = (link, e) => {
    if (isPreviewMode) {
      e.preventDefault();
      onPreviewCTA();
      return;
    }
    if (!isMobile()) {
      e.preventDefault();
    }
    onStravaFollow(link);
  };

  const followOnInstagram = (link, e) => {
    if (isPreviewMode) {
      e.preventDefault();
      onPreviewCTA();
      return;
    }
    if (!isMobile()) {
      e.preventDefault();
    }
    onInstagramFollow(link);
  };

  useEffect(() => {
    const availableFeatures = [];
    if (features && features.length > 0) {
      features.forEach((feature) => {
        availableFeatures.push(feature.featureName);
      });
      setMembershipFeatures(availableFeatures);
    }
  }, [features]);

  useEffect(() => {
    if (membershipId && !isPreviewMode) {
      getInstagramConfig(membershipId);
    }
  }, [membershipId]);

  return (
    <div className="benefits-summary">
      <div className="benefits-summary__header">
        <div className="benefits-summary__header--title">
          Earn Points
        </div>
      </div>

      <div className={readOnly ? 'custom-benefits-wrapper' : ''}>
        <div className="benefits-highlighted">
          {membershipFeatures.includes('Referrals')
        && (
        <div className="benefits-highlighted__item">
          <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/ethos/featureIcons/icon-friends.png" alt="Refer a friend" />
          <div className="benefits-highlighted__title">Refer your friends to join</div>
          <div className="benefits-highlighted__description">25 points</div>
          {!readOnly && (
          <div className="benefits-highlighted__cta">
            <button
                type="button"
                className="button-collection"
                onClick={() => onReferFriend()}>
              Refer
            </button>
          </div>
          )}
        </div>
        )}
          {/* <div className="benefits-highlighted__item">
            <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/ethos/featureIcons/icon-purchase.png" alt="Make a purchase" />
            <div className="benefits-highlighted__title">Subscribe to a product</div>
            <div className="benefits-highlighted__description">2 points for every $1 you spend</div>
            {!readOnly && (
            <div className="benefits-highlighted__cta">
              <a href={process.env.CLIENT_WEBSITE} target="_blank" className="button-collection" rel="noreferrer">Shop</a>
            </div>
            )}
          </div> */}
          <div className="benefits-highlighted__item">
            <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/ethos/featureIcons/icon-purchase.png" alt="Make a purchase" />
            <div className="benefits-highlighted__title">Make a purchase</div>
            <div className="benefits-highlighted__description">1 point for every $1 you spend</div>
            {!readOnly && (
            <div className="benefits-highlighted__cta">
              <a href={process.env.CLIENT_WEBSITE} target="_blank" className="button-collection" rel="noreferrer">Shop</a>
            </div>
            )}
          </div>
          {membershipFeatures.includes('ShopReferrals')
          && (
          <div className="benefits-highlighted__item">
            <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/ethos/featureIcons/icon-share.png" alt="Share link to shop" />
            <div className="benefits-highlighted__title">Share your shoppable link</div>
            <div className="benefits-highlighted__description">25 points</div>
            {!readOnly && (
            <div className="benefits-highlighted__cta">
              <button
                  type="button"
                  className="button-collection"
                  onClick={() => onShareLink()}>
                Share
              </button>
            </div>
            )}
          </div>
          )}

          {membershipFeatures.includes('BirthdayPoints')
          && (
          <div className="benefits-highlighted__item">
            <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/ethos/featureIcons/icon-birthday.png" alt="Celebrate your birthday" />
            <div className="benefits-highlighted__title">Celebrate your birthday</div>
            <div className="benefits-highlighted__description">10 points</div>
            {!readOnly && (
            <>
              {AccountManager.hasBirthdate()
                ? (
                  <div className="benefits-highlighted__cta">
                    <img className="benefits-birthday__img" src="https://assets.heyethos.com/ethos/v2/ui/icon-check-dark.png" alt="Successfully added your birthday" />
                  </div>
                )
                : (
                  <div className="benefits-highlighted__cta">
                    {isPreviewMode ? (
                      <a onClick={() => onPreviewCTA()} className="button-collection">Add birthday</a>
                    ) : (
                      <a href="/account/details" className="button-collection">Add birthday</a>
                    )}
                  </div>
                )}
            </>
            )}
          </div>
          )}

          {((membershipFeatures.includes('InstagramFollow') && instagramUrl) || isPreviewMode)
          && (
          <div className="benefits-highlighted__item">
            <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/ethos/featureIcons/icon-tag-instagram.png" alt="Follow us on Instagram" />
            <div className="benefits-highlighted__title">Follow us on Instagram</div>
            <div className="benefits-highlighted__description">10 points</div>
            {!readOnly && (
            <>
              {isFollowing
                ? (
                  <div className="benefits-highlighted__cta">
                    <img className="benefits-birthday__img" src="https://assets.heyethos.com/ethos/v2/ui/icon-check-dark.png" alt="Successfully followed" />
                  </div>
                )
                : (
                  <div className="benefits-highlighted__cta">
                    <a href={'https://www.instagram.com/' + instagramUrl} target="_blank" onClick={(e) => followOnInstagram('https://www.instagram.com/' + instagramUrl, e)} className="button-collection" rel="noreferrer">Follow</a>
                  </div>
                )}
            </>
            )}
          </div>
          )}

          {(membershipFeatures.includes('StravaFollow'))
          && (
          <div className="benefits-highlighted__item">
            <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/ethos/featureIcons/icon-strava.png" alt="Follow us on Strava" />
            <div className="benefits-highlighted__title">Follow us on Strava</div>
            <div className="benefits-highlighted__description">10 points</div>
            {!readOnly && (
              <>
                {isFollowingStrava ? (
                  <div className="benefits-highlighted__cta">
                    <img className="benefits-birthday__img" src="https://assets.heyethos.com/ethos/v2/ui/icon-check-dark.png" alt="Successfully followed" />
                  </div>
                ) : (
                  <div className="benefits-highlighted__cta">
                    <a href={'https://www.strava.com/clubs/' + stravaUrl} target="_blank" onClick={(e) => followOnStrava('https://www.strava.com/clubs/' + stravaUrl, e)} className="button-collection" rel="noreferrer">Follow</a>
                  </div>
                )}
              </>
            )}
          </div>
          )}
        </div>

      </div>
    </div>
  );
}

export default CustomBenefits;
